/* global $, WonderBlah */
/**
 * Copyright (C) WonderBlah - All Rights Reserved
 *
 * This source file is Proprietary and confidential.
 * Use, copy, modification or distribution of this source file without written
 * permission from WonderBlah is strictly forbidden.
 *
 */

$(document).ready(() => {
  $('#EXBLAH').click(() => {
    window.location.href = WonderBlah.urls.createABlah;
  });
  $('#closeContinueOrNewBlah').click(() => {
    window.location.href = WonderBlah.urls.createABlah;
  });
});
