/* global $, WonderBlah */
/**
 * Copyright (C) WonderBlah - All Rights Reserved
 *
 * This source file is Proprietary and confidential.
 * Use, copy, modification or distribution of this source file without written
 * permission from WonderBlah is strictly forbidden.
 *
 */

//import './lib/redirectmobile';
import lightbox from './lib/lightbox';

import './components/menu.js';
import './components/user.js';

// import './front/blahvo_game.js';
import './front/create_a_blah.js';

$(document).ready(() => {
  console.log('Wonderblah', WonderBlah);

  lightbox.option({
    wrapAround: true,
  });
});
